import React from 'react';
import { Grid } from 'theme-ui';

function Stack({ children, align = 'start', justify = 'normal', space = 2 }) {
  return (
    <Grid
      sx={{ gridRowGap: space, alignContent: align, justifyContent: justify }}
    >
      {children}
    </Grid>
  );
}

export default Stack;
