import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Flex, Box, Container } from 'theme-ui';
import { ReactQueryDevtools } from 'react-query-devtools';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GlobalStyle from './GlobalStyle';

const Layout = ({
  children,
  fullWidth,
  transparentHeader = false,
  stickyHeader = true,
  showNavigateBackInHeader = false,
  showFooter = true,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => {
      const Main = fullWidth ? Box : Container;
      return (
        <>
          <ReactQueryDevtools initialIsOpen={false} />
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="default"
            />
          </Helmet>
          <GlobalStyle />
          <Flex
            sx={{
              background: 'linear-gradient(white,85%, #e6ece8)',
              position: 'relative',
              zIndex: 1,
              flexDirection: 'column',
              flexGrow: 1,
              minHeight: '100vh',
            }}
          >
            <Header
              transparent={transparentHeader}
              sticky={stickyHeader}
              title={data.site.siteMetadata.title}
              navigateBackButton={showNavigateBackInHeader}
            />
            <Main sx={{ flexGrow: 1 }}>{children}</Main>
            {showFooter ? <Footer sx={{ justifySelf: 'flex-end' }} /> : null}
          </Flex>
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
