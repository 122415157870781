const baseUrl = 'https://strapi.reel-north.com';

export default {
  map: {
    defaultCenter: {
      // sundsvall
      lat: 62.3908,
      lng: 17.3069,
    },
    defaultZoomLevel: {
      desktop: 5,
      mobile: 2,
    },
  },
  api: {
    baseUrl,
    graphql: `${baseUrl}/graphql`,
  },
  ad: {
    imageUrl: 'https://s3.eu-west-1.amazonaws.com/cdn.wefishtheworld.com/5ffc58566a8240aca46bfea8777cc966.png',
    targetUrl: 'https://www.sportfiskeprylar.se',
  },
  footer: {
    affiliate: {
      title: 'Fyll i detta formulär om du som guide eller hotell också vill synas på hemsidan:',
      link: 'https://forms.gle/NJze9jU8JfxNNNPz9',
    },
    contact: {
      title: 'Kontakt',
      email: 'info@reel-north.com',
    },
    company: {
      title: 'REEL NORTH',
      address: `Vårt mål är att samla alla de bästa fiskeguider och fiskecampingar i Svergie! 
      Det ska vara lätt att hitta nya ställen att bo och fiska på.`,
    },
  },
};
