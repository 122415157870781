import React from 'react';
import { Text as T, Heading as H } from 'theme-ui';

function Heading({ children, ...rest }) {
  return (
    <H
      sx={{
        fontSize: [3, 4],
      }}
      {...rest}
    >
      {children}
    </H>
  );
}

function Text({ children, ...props }) {
  return <T {...props}>{children}</T>;
}

export function P({ children, sx = {}, ...rest }) {
  return (
    <Text as="p" {...rest} sx={{ fontSize: [1, 2], ...sx }}>
      {children}
    </Text>
  );
}
export function Span({ children, ...rest }) {
  return (
    <Text as="span" {...rest}>
      {children}
    </Text>
  );
}

export function Hero({ children, sx = {}, ...rest }) {
  return (
    <Heading as="h1" {...rest} sx={{ ...sx, fontSize: [5, 6] }}>
      {children}
    </Heading>
  );
}
export function H1({ children, sx = {}, ...rest }) {
  return (
    <Heading as="h1" {...rest} sx={{ ...sx, fontSize: [4, 5] }}>
      {children}
    </Heading>
  );
}

export function H2({ children, sx = {}, ...rest }) {
  return (
    <Heading as="h2" {...rest} sx={{ ...sx, fontSize: [3, 4] }}>
      {children}
    </Heading>
  );
}
export function H3({ children, sx = {}, ...rest }) {
  return (
    <Heading as="h3" {...rest} sx={{ ...sx, fontSize: [2, 3] }}>
      {children}
    </Heading>
  );
}
export function H4({ children, sx = {}, ...rest }) {
  return (
    <Heading as="h4" {...rest} sx={{ ...sx, fontSize: [1, 2] }}>
      {children}
    </Heading>
  );
}
export function H5({ children, sx = {}, ...rest }) {
  return (
    <Heading as="h5" {...rest} sx={{ ...sx, fontSize: [0, 1] }}>
      {children}
    </Heading>
  );
}
export function H6({ children, sx = {}, ...rest }) {
  return (
    <Heading as="h6" {...rest} sx={{ ...sx, fontSize: [0, 1] }}>
      {children}
    </Heading>
  );
}

export default {
  P,
  Span,
  Text,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Hero,
};
