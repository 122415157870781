import React from 'react';
import { Grid, Container, Box, Link } from 'theme-ui';
import Stack from '../Stack';
import Text from '../Text';
import config from '../../config';

const SecondaryParagraph = ({ children }) => (
  <Text.P variant="secondary" sx={{ whiteSpace: 'pre-line' }}>
    {children}
  </Text.P>
);

function Footer({ children, ...rest }) {
  const { footer: { affiliate, contact, company } = {} } = config;
  return (
    <Box
      sx={{
        mx: 'auto',
        color: 'text',
        width: '100%',
        minHeight: '25vh',
        height: '100%',
        position: 'relative',
      }}
    >
      <Container sx={{ position: 'relative' }} {...rest}>
        <Grid
          sx={{ justifyItems: [null, 'center'] }}
          px={3}
          py={5}
          mb={4}
          columns={[1, 1, 3]}
        >
          <Stack>
            <Text.H4 as="p">{company.title}</Text.H4>
            <Box>
              <SecondaryParagraph>{company.address}</SecondaryParagraph>
            </Box>
          </Stack>
          <Stack>
            <Text.H4 as="p">{contact.title}</Text.H4>
            <SecondaryParagraph>{contact.email}</SecondaryParagraph>
          </Stack>
          <Stack>
            <Text.H4 as="p">{affiliate.title}</Text.H4>
            <SecondaryParagraph>{affiliate.link}</SecondaryParagraph>
          </Stack>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
