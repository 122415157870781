import React from 'react';
import { Global } from '@emotion/core';

export default () => (
  <Global
    styles={() => ({
      '*': {
        boxSizing: 'border-box',
      },
    })}
  />
);
