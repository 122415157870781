import React from 'react';
import { Link, Box, Flex } from 'theme-ui';
import { navigate } from 'gatsby';
import { lighten } from '@theme-ui/color';
import safeWindow from '../../util/safeWindow';
import Text from '../Text';

function Header({
  title,
  transparent = false,
  sticky = true,
  navigateBackButton = false,
}) {
  return (
    <Flex
      sx={{
        position: sticky ? 'sticky' : 'static',
        top: 0,
        zIndex: 10,
        boxShadow: (theme) =>
          transparent
            ? 'none'
            : `0px 1px 2px ${lighten('accent-4', 0.26)(theme)}`,
        minHeight: '3.5rem',
        alignItems: 'center',
        justifyContent: 'center',
        bg: transparent ? 'none' : 'white',
        px: 2,
        py: 2,
      }}
    >
      <Box
        sx={{
          display: navigateBackButton ? 'block' : 'none',
          cursor: 'pointer',
        }}
        onClick={() =>
          safeWindow((x) => x.history).length > 1
            ? safeWindow((x) => x.history).go(-1)
            : navigate('/find')
        }
      >
        <Text.P>
          {safeWindow((x) => x.history).length > 1 ? 'Tillbaka' : 'Sök'}
        </Text.P>
      </Box>
      <Text.H4
        as="p"
        variant="caps"
        mx="auto"
        sx={{
          fontWeight: 'bold',
        }}
      >
        <Link
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            fontWeight: 'inherit',
          }}
          href="/"
        >
          {title}
        </Link>
      </Text.H4>
    </Flex>
  );
}

export default Header;
